<template>
  <div>
    <image-header :text="$t('partner.image-header')">
      <img style="object-position: center" src="@/assets/header_images/1.jpg">
    </image-header>
    <content-sheet>
      <h1 class="text--primary">{{ $t('partner.sec1-title') }}</h1>
      <div class="partner-text text-left" style="justify-content: space-between">
        <p style="white-space: pre-wrap;">{{ $t('partner.sec1-left') }}</p>
        <p style="white-space: pre-wrap;">
          {{ $t('partner.sec1-right') }}
          <br><br><span class="text-bold">Mats, Mari och Gunnar</span>
        </p>
      </div>
    </content-sheet>
    <content-sheet style="margin-top: 100px">
      <h1 class="text--primary">{{ $t('partner.sec2-title') }}</h1>
      <p style="white-space: pre-wrap; max-width: 700px; margin: 0 auto">{{ $t('partner.sec2-info') }}</p>

      <!-- <p style="max-width: 700px; margin: 0 auto">Vi välkomnar er att ta steget och bli sponsor. Företag, församlingar och organisationer kan enkelt bli sponsorer och samtidigt synas på vår hemsida.</p>
      <p style="max-width: 700px; margin: 0 auto; padding-top: 20px">Stöd vår vision och vårt arbete med 10 000 kr/år och er logga kommer att synas här.</p> -->
      <button @click="$store.commit('showModal', 0)" class="row-vertical-center" style="margin: 0 auto; justify-content: center; margin-top: 40px"><IconGift style="margin-right: 10px"/>{{ $t('partner.sec2-button') }}</button>
    
      <div class="sponsor-list">
        <div class="sponsor">
          <img src="@/assets/sponsor-images/runeke_konsult.png" class="gold-frame" alt="Runeke Konsult" />
          <h3 class="gold-name text--white" style="letter-spacing: 0.38em">{{ $t('partner.sec2-golden') }}</h3>
        </div>
        <img src="@/assets/sponsor-images/energikonsult.png" class="sponsor" alt="Energisparkonsult AB" />
        <img src="@/assets/sponsor-images/huslakare_i_hoor.png" class="sponsor" alt="Husläkaren i Höör" />
      </div>
    </content-sheet>
  </div>
</template>

<script>
import ContentSheet from '../components/ContentSheet.vue'
import ImageHeader from '../components/ImageHeader.vue'
import IconGift from '../assets/icons/gift_fill.svg'

export default {
  components: {
    ContentSheet,
    ImageHeader,
    IconGift,
  },
  metaInfo() {
    return {
      title: this.$t('navbar.partner')
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style>
.partner-text {
  display: flex;
  flex-direction: row;
}
.partner-text > p {
  flex: 0 0 48%;
}
.sponsor-list {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 40px;
}
.sponsor {
  box-sizing: border-box;
  max-width: 300px;
  margin: 20px;
}
.sponsor-list > img {
  min-width: 0;
}
.gold-frame {
  width: 100%;
  display: block;
  box-sizing: border-box;
  border: 10px solid #e5b409;
  padding: 10px;
}
.gold-name {
  background-color: #e5b409;
  width: 100%;
  box-sizing: border-box;
  margin: 7px 0 0;
  padding: 7px 0;
}

@media screen and (max-width: 700px) {
  .partner-text {
    flex-direction: column;
  }
  .partner-text > p {
    flex: 1;
    margin-bottom: 20px;
  }
}
</style>